import axios from "../instance";

export default {
  verifyByEmail: async (body) => {
    return axios.api
      .post(`/users/verifyEmail`, body)
      .then((response) => response.data);
  },

  resetPassword: async (body) => {
    return axios.api
      .post(`/users/forgetPassword`, body)
      .then((response) => response.data);
  },

  resetApprovalPassword: async (body) => {
    return axios.api
      .post(`/users/changeApprovalPassword`, body)
      .then((response) => response.data);
  },
};
