import axios from "../instance";

export default {
  getAll: () => {
    return axios.api.get("/company").then((response) => response.data);
  },

  getAllByUser: (createBy) => {
    return axios.api
      .get(`/company/companyByUser?createBy=${createBy}`)
      .then((response) => response.data);
  },

  getOne: (id) => {
    return axios.api.get(`/company/${id}`).then((response) => response.data);
  },

  generateNewData: () => {
    return axios.api
      .get(`/company/generateNewData`)
      .then((response) => response.data);
  },

  checkEmailCompany: (body) => {
    return axios.api
      .post(`/checkEmail`, body)
      .then((response) => response.data);
  },

  create: (body) => {
    return axios.api.post(`/company`, body).then((response) => response.data);
  },

  update: (id, body) => {
    return axios.api
      .put(`/company/${id}`, body)
      .then((response) => response.data);
  },

  delete: (id) => {
    return axios.api.delete(`/company/${id}`).then((response) => response.data);
  },

  search: (body) => {
    return axios.api
      .post(`/company/search`, body)
      .then((response) => response.data);
  },
};
