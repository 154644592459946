import axios from "../instance";
import addQuery from "../../utils/addQuery";

export default {
  getAll: async (payload) => {
    const result = await addQuery(payload);
    return axios.api
      .get(`/companyBranch?${result}`)
      .then((response) => response.data);
  },

  // getAllByUser: (createBy) => {
  //   return axios.api
  //     .get(`/companyBranch/companyByUser?createBy=${createBy}`)
  //     .then((response) => response.data);
  // },

  getOne: (id) => {
    return axios.api
      .get(`/companyBranch/${id}`)
      .then((response) => response.data);
  },

  // generateNewData: () => {
  //   return axios.api
  //     .get(`/companyBranch/generateNewData`)
  //     .then((response) => response.data);
  // },

  // checkEmailCompany: (body) => {
  //   return axios.api
  //     .post(`/checkEmail`, body)
  //     .then((response) => response.data);
  // },

  create: (body) => {
    return axios.api
      .post(`/companyBranch`, body)
      .then((response) => response.data);
  },

  update: (id, body) => {
    return axios.api
      .put(`/companyBranch/${id}`, body)
      .then((response) => response.data);
  },

  delete: (id) => {
    return axios.api
      .delete(`/companyBranch/${id}`)
      .then((response) => response.data);
  },

  search: (body) => {
    return axios.api
      .post(`/companyBranch/search`, body)
      .then((response) => response.data);
  },
};
