import login from "./login";
import register from "./register";
import company from "./company";
import companyBranch from "./companyBranch";
import user from "./user";
import upload from "./upload";

export default {
  login,
  register,
  company,
  companyBranch,
  user,
  upload,
};
