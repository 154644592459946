import axios from "../instance";
const companyId = localStorage.getItem("companyId");

export default {
  getAll: (confirm) => {
    if (confirm) {
      return axios.api.get(`/users`).then((response) => response.data);
    } else {
      return axios.api
        .get(`/users?companyId=${companyId}`)
        .then((response) => response.data);
    }
  },

  getOne: (id) => {
    return axios.api.get(`/users/${id}`).then((response) => response.data);
  },

  update: (id, body) => {
    return axios.api
      .put(`/users/${id}`, body)
      .then((response) => response.data);
  },

  updateForgetPass: (email, body) => {
    return axios.api
      .put(`/users/updateForgetPass/${email}`, body)
      .then((response) => response.data);
  },

  delete: (id) => {
    return axios.api.delete(`/users/${id}`).then((response) => response.data);
  },

  approval: (body) => {
    return axios.api
      .post(`/users/approval`, body)
      .then((response) => response.data);
  },
};
