import axios from "../instance";

export default {
  checkEmailUser: (body) => {
    return axios.api
      .post(`/users/checkEmail`, body)
      .then((response) => response.data);
  },

  checkUserName: (body) => {
    return axios.api
      .post(`/users/checkUsername`, body)
      .then((response) => response.data);
  },

  addUser: (body) => {
    return axios.api
      .post("/users/createAdmin", body)
      .then((response) => response.data);
  },
};
