import axios from "../instance";

export default {
  upload: (body) => {
    return axios.api
      .post(`/upload/formData`, body)
      .then((response) => response.data);
  },

  delete: (payload) => {
    return axios.api
      .post(`/upload/remove`, payload)
      .then((response) => response.data);
  },

  // addUser: (body) => {
  //   return axios.api
  //     .post("/users/createAdmin", body)
  //     .then((response) => response.data);
  // },
};
